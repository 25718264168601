import type { AxiosInstance } from 'axios'
import type { ComponentType } from 'react'
import type { History, Location } from 'history'
import { Provider } from 'react-redux'
import { Router, RouterContext } from 'react-router'
import type { RouterState } from 'react-router'
import type { Store } from 'redux'
import { useEffect, useRef } from 'react'

import { ApiProvider } from './components/ApiProvider'
import { Document } from './Document'
import { getLinkDataForRoute, getMetaDataForRoute } from './utils/routeData'
import { hydrateNavigation, updateView } from './store/actions'
import { sendPageView, setLocation } from './utils/googleAnalytics'
import { track } from './utils/tracking'
import DelayUpdate from './components/DelayUpdate'
import GotoProvider from './components/GotoProvider'
import I18nProvider from './components/I18nProvider'
import getRoutes from './routes'
import runAllViewStoreUpdates from './utils/runAllViewStoreUpdates'

type Props = {
  store: Store & { api: AxiosInstance }
  history: History
  storeInitialState: State
  additionalScripts: Array<string>
  googleTagManagerId: string
}

export default function App({
  store,
  history,
  storeInitialState,
  additionalScripts,
  googleTagManagerId,
}: Readonly<Props>) {
  const storeState = store.getState()
  const isEditor = storeState.getIn(['view', 'editorMode'])
  const mboBaseUrl = storeState.getIn(['shop', 'mboBaseUrl'])

  const trackPageViewTimeoutId = useRef(0)
  const trackPageView = useRef(getPageViewTracker())

  // For scroll restoration
  const scrollYPositions = useRef({})
  const scrollHeights = useRef({})
  const lastLocation = useRef<Location>()
  const restoreScrollPosition = (key: string, iteration = 1) => {
    // This only iterates more than once in case of (image) layout jank, which
    // should be avoided. Try up to 5 iterations. Firefox needed up to 5 in
    // test cases, other browsers needed less.
    const maxTries = 5
    if (iteration !== maxTries && document.body.scrollHeight < scrollHeights.current[key]) {
      // We might have layout jank: defer to next event cycle.
      window.setTimeout(() => restoreScrollPosition(key, iteration + 1))
    } else {
      window.scrollTo(0, scrollYPositions.current[key] || 0)
    }
  }

  useEffect(() => {
    // http://stackoverflow.com/a/33004917
    // Tell the browser that we take care of restoring the last scroll position ourselves.
    // See `beforeViewUpdate` below.
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }

    // Initialize additional scripts, mainly third-party scripts of storefront apps.
    // These scripts are preloaded with HTML `<link rel="preload">` elements via SSR.
    additionalScripts.forEach((src) => {
      const script = document.createElement('script')
      script.src = src
      document.head.appendChild(script)
    })

    store.dispatch(hydrateNavigation())
  }, [additionalScripts, store])

  let firstRender = true
  const routerRender = (routerProps: RouterState) => {
    const nextLocation = routerProps.location

    // create a list of store update promises per view component to render
    // and set or clear view error in store according to overall outcome
    const skipStoreUpdates = firstRender && Boolean(storeInitialState)

    const shouldTrackPageView =
      // don't track when rendered in editor
      !isEditor &&
      // don't track when rendered within an iframe (e.g. theme preview)
      window.top === window.self

    // after this promise everything is ready for the view to be rendered
    const beforeViewUpdate = (async () => {
      await runAllViewStoreUpdates({ routerProps, store, skip: skipStoreUpdates })

      if (nextLocation.action === 'PUSH' && lastLocation.current) {
        scrollHeights.current[lastLocation.current.key] = document.body.scrollHeight
        scrollYPositions.current[lastLocation.current.key] = window.scrollY
      }
    })()

    const createElement = (Component: ComponentType, routerProps: RouterState) => {
      return (
        <DelayUpdate promise={beforeViewUpdate}>
          <GotoProvider history={history} store={store}>
            <I18nProvider Component={Component} {...routerProps} />
          </GotoProvider>
        </DelayUpdate>
      )
    }

    ;(async () => {
      await beforeViewUpdate

      const meta = await getMetaDataForRoute(routerProps, store.getState())
      const link = await getLinkDataForRoute(routerProps, store.getState())
      store.dispatch(updateView(meta, link))

      if (isEditor) {
        // tell the MBO/Cockpit where we are (aka. "View your website")
        window.parent.postMessage(
          {
            type: 'PREVIEW_PATH',
            // Note: this discards all query parameters, but that's fine since we don't have any.
            // If we need to pass them along later, we'll have to exclude ['editor', 'shop', 'token'].
            payload: nextLocation.pathname.replace(/\/editor(\/themes)?/, '') || '/',
          },
          mboBaseUrl,
        )
      }

      if (shouldTrackPageView) {
        // in order that GA sends the correct pageview field values (e.g. page title),
        // defer the tracking call to wait for the page to be rendered
        if (trackPageViewTimeoutId.current) window.clearTimeout(trackPageViewTimeoutId.current)
        trackPageViewTimeoutId.current = window.setTimeout(() => {
          trackPageView.current(nextLocation)
        }, 1)
      }

      // Scroll restoration
      if (firstRender) {
        // don't scroll to top if the user has scrolled down while the JS was still loading
      } else if (nextLocation.action === 'PUSH' && nextLocation.state?.scrollToTop !== false) {
        window.scrollTo(0, 0)
      } else if (nextLocation.action === 'POP' && !nextLocation.hash) {
        restoreScrollPosition(nextLocation.key)
      } else {
        // eagerly store scroll position reached via { scrollToTop: false },
        // so it can be restored upon browser forward navigation
        scrollYPositions.current[nextLocation.key] = window.scrollY
      }

      // Anchor time!
      if (nextLocation.hash) {
        document.querySelector(nextLocation.hash)?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }

      lastLocation.current = nextLocation
      firstRender = false
    })()

    return <RouterContext {...routerProps} createElement={createElement} />
  }

  return (
    <Provider store={store}>
      <ApiProvider api={store.api}>
        <Document googleTagManagerId={googleTagManagerId}>
          <Router history={history} render={routerRender}>
            {getRoutes(storeInitialState.getIn(['shop', 'locales']).toJS())}
          </Router>
        </Document>
      </ApiProvider>
    </Provider>
  )
}

export function getPageViewTracker() {
  let lastTrackedPathname = ''
  let lastTrackedSearch = ''

  return function trackPageView({ pathname, search }) {
    if (lastTrackedPathname !== pathname || lastTrackedSearch !== search) {
      lastTrackedPathname = pathname
      lastTrackedSearch = search

      track('page:view', { url: pathname + search })
      setLocation(location)
      sendPageView(pathname)
    }
  }
}
