import type { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch } from 'react-redux'
import { useEffect, useRef } from 'react'

import { setEditorInert } from '../../store/actions/view'

type EditorSpotlightProps = Readonly<{
  children: ReactNode
  referenceElement: HTMLElement | null
  onCancel?: VoidFunction
}>

/**
 * Component that is used to draw attention to an element in the main content
 * area of the editor by visually highlighting it and preventing interaction
 * with the rest of the page, except that scrolling the main content of the
 * editor is still possible.
 *
 * **Note**: It’s important to size the referenced element exactly to the area
 * that should be highlighted since the spotlight is sized and positioned based
 * on this element.
 *
 * @param referenceElement A reference element to size and position the spotlight to
 * @param children The content of the spotlight (portal’d to the body of the document)
 * @param onCancel Callback function that is called when closing with the Escape key
 */
export function EditorSpotlight({ children, referenceElement, onCancel }: EditorSpotlightProps) {
  const dialogRef = useRef<HTMLDialogElement>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const dialog = dialogRef.current

    if (referenceElement && dialog) {
      const editorScrollContainer = document.getElementById('body-wrapper')

      // Enables cancelling the spotlight with the Escape key.
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') onCancel?.()
      }

      // Enables scrolling the main content of the editor with a wheel button
      // (e.g. scrolling with a mouse wheel).
      let wheelTimeout: number | null = null
      const handleWheel = () => {
        dialog.style.pointerEvents = 'none'
        if (wheelTimeout) clearTimeout(wheelTimeout)
        wheelTimeout = window.setTimeout(() => {
          // We check the ref in case the handler is called after the component
          // has been unmounted.
          if (dialogRef.current) dialogRef.current.style.pointerEvents = ''
          wheelTimeout = null
        }, 100)
      }

      // Updates the size and position of the spotlight based on the reference element.
      const updateStyle = () => {
        window.requestAnimationFrame(() => {
          const rect = referenceElement.getBoundingClientRect()
          dialog.style.width = `${rect.width}px`
          dialog.style.top = `${rect.top + window.scrollY}px`
          dialog.style.left = `${rect.left + window.scrollX}px`
        })
      }

      updateStyle()
      dispatch(setEditorInert(true))
      editorScrollContainer?.addEventListener('scroll', updateStyle)
      window.addEventListener('keydown', handleKeyDown)
      window.addEventListener('resize', updateStyle)
      dialog.addEventListener('wheel', handleWheel)

      return () => {
        dispatch(setEditorInert(false))
        editorScrollContainer?.removeEventListener('scroll', updateStyle)
        window.removeEventListener('keydown', handleKeyDown)
        window.removeEventListener('resize', updateStyle)
        dialog.removeEventListener('wheel', handleWheel)
      }
    }
  }, [dispatch, referenceElement, onCancel])

  return createPortal(
    <dialog ref={dialogRef} open className="ep-editor-spotlight">
      {children}
    </dialog>,
    document.body,
  )
}
