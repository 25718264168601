import { mimeTypeMap } from '../templateComponents/Workspace/daliConfig'

export async function getFileFromCanvas(canvas: HTMLCanvasElement, filename: string) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const mimeType = mimeTypeMap[filename.toLowerCase().split('.').pop()!]
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => resolve(blob), mimeType)
  })

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return new File([blob!], filename, { type: mimeType })
}

export async function getFileFromImage(image: HTMLImageElement, filename: string) {
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  canvas.getContext('2d')?.drawImage(image, 0, 0)
  return getFileFromCanvas(canvas, filename)
}

export function getFilename(src: string) {
  const url = new URL(src, window.location.origin)
  return url.searchParams.has('remote')
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      decodeURIComponent(url.searchParams.get('remote')!.split('/').pop()!)
    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      decodeURIComponent(url.pathname.split('/').pop()!)
}

export function getRelativeDelta(delta: number, baseSize: number, baseClientSize: number) {
  const base = (1 / baseSize) * baseClientSize
  return delta / base
}
