import type { MouseEventHandler } from 'react'

export type ImageMoveControls = Record<ImageMoveControlDirection, Omit<ImageMoveControlProps, 'label'>>
type ImageMoveControlDirection = 'up' | 'down' | 'left' | 'right'
type ImageMoveControlsProps = Readonly<{
  controls: ImageMoveControls
  t: TranslateProps['t']
}>
type ImageMoveControlProps = Readonly<{
  direction: ImageMoveControlDirection
  disabled?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  label: string
}>

export function ImageMoveControls({ controls, t }: ImageMoveControlsProps) {
  return (
    <fieldset className="ep-image-editor-move-controls">
      <legend className="visually-hidden">{t('imageMoveControls.accessibilityLabel')}</legend>
      <ImageMoveControl {...controls.up} label={t('imageMoveControls.moveUpButton.accessibilityLabel')} />
      <ImageMoveControl {...controls.down} label={t('imageMoveControls.moveDownButton.accessibilityLabel')} />
      <ImageMoveControl {...controls.left} label={t('imageMoveControls.moveLeftButton.accessibilityLabel')} />
      <ImageMoveControl {...controls.right} label={t('imageMoveControls.moveRightButton.accessibilityLabel')} />
    </fieldset>
  )
}

function ImageMoveControl({ direction, disabled, onClick, label }: ImageMoveControlProps) {
  return (
    <div className="ep-image-editor-move-control" data-direction={direction}>
      <button type="button" disabled={disabled} onClick={onClick} aria-label={label} title={label} />
    </div>
  )
}
