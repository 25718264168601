import type { ReactElement } from 'react'
import cc from 'classcat'

import { imageUrl } from '../../ThemeView'
import LazyImage from '../../LazyImage'

type Props = {
  paymentLogos?: {
    id: string
    path?: string
    title?: string
    filename?: string
  }[]
} & TranslateProps

export default function Payments({ paymentLogos = [], t }: Readonly<Props>): ReactElement | null {
  if (!paymentLogos.length) return null

  return (
    <ul className="payments">
      {paymentLogos.map((logo) => (
        <li className="payments-item" key={logo.id}>
          <div className="payments-item-box">
            <LazyImage
              className={cc(['payments-item-box-logo', logo.path && 'payments-item-box-logo-custom'])}
              src={logo.path ? imageUrl(logo.path, {}) : `/themes/epages.base/assets/payments/${logo.filename}`}
              srcSet={
                logo.path
                  ? `${imageUrl(logo.path, { height: 22 })} 1x, ${imageUrl(logo.path, { height: 44 })} 2x`
                  : undefined
              }
              alt={logo.title || t(`components.bottomBar.footerSettings.paymentLogos.paymentNames.${logo.id}`)}
              title={logo.title || t(`components.bottomBar.footerSettings.paymentLogos.paymentNames.${logo.id}`)}
            />
          </div>
        </li>
      ))}
    </ul>
  )
}
