import { Form } from '@epages/react-components'
import { createElement, useRef, useState } from 'react'
import Immutable from 'immutable'
import cc from 'classcat'
import loadable from '@loadable/component'

import ImageField, { withImageData, withMergedImageData } from './ImageField'
import { ImageEditor } from '../../../../ImageEditor'
import CtaTextOverlay from '../components/CtaTextOverlay'
import LazyImage from '../../../../LazyImage'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import useFeatureToggle from '../../../../../utils/hooks/useFeatureToggle'
import useHelpCenterLink from '../../../../../utils/hooks/useHelpCenterLink'
import useUpdateSettingsLayerPosition from '../../../../../utils/hooks/useUpdateSettingsLayerPosition'
import withI18n from '../../../../withI18n'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const Settings = loadable(() => import(/* webpackChunkName: "editor" */ './ImageSettings'))
const CropSettings = loadable(() => import(/* webpackChunkName: "editor" */ './ImageCropSettings'))

const defaultData = Immutable.fromJS({
  src: '',
  width: null,
  height: null,
  alt: '',
  link: '',
  opentab: false,
  text: '',
  buttontext: '',
  headline: '',
  buttonenabled: false,

  // Reference to the original image (image before any modifications).
  // Initially, after image upload, this is always `undefined`. The property is
  // first set on first image editor "init" event. Before that, the "src"
  // property has the reference to the original image.
  // See `ImageEditor` use below.
  refSrc: '',

  // Image editing settings (used with the image editor).
  edit: {
    aspectRatio: { label: undefined, value: undefined },
    offset: [0, 0], // [x, y]
    zoom: 1,
  },
})

// Temp function for development to remove image editor data from the data
// object before save unless the image editor feature is enabled.
function withoutImageEditorData(data: Immutable.Map<string, any>) {
  return data.remove('refSrc').remove('edit')
}

export type ImagePluginProps = WorkspacePluginProps & TranslateProps

function ImagePlugin({
  config,
  editorView,
  editorMode,
  editAction,
  isMultiColumn,
  data = defaultData,
  onDataChange,
  onSave,
  onCancel,
  onEdit,
  t,
}: ImagePluginProps) {
  const [isSettingActive, setIsSettingActive] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const ref = useRef<HTMLDivElement>(null)
  const updateSettingsLayerPosition = useRef<VoidFunction>()
  const helpCenterUrl = useHelpCenterLink('IMAGE_FORMATS')

  const isUnsupportedMediaType = error?.code === 415
  const isEmpty = !data.get('src')
  const isLinked = Boolean(data.get('link'))
  const pluginActiveClasses = cc([
    'dali-plugin-image',
    {
      'dali-grid-element-highlighted': isSettingActive,
    },
  ])

  const hasImageEditorFeature = useFeatureToggle('imageEditor')

  useUpdateSettingsLayerPosition(updateSettingsLayerPosition)

  const handlePluginActiveStateChange = (isActive: boolean) => {
    setIsSettingActive(isActive)
  }

  const handleImageLoaded = () => {
    if (editorMode === 'edit') {
      updateSettingsLayerPosition.current?.()
    }
  }

  const emptyImage = (
    <div className="dali-grid-element-placeholder">
      <div className="dali-settingslayer-element">
        {error ? (
          <div className="dali-notification-danger">
            {error.message}{' '}
            {isUnsupportedMediaType && (
              <a
                href={helpCenterUrl}
                target="_blank"
                className="ep-form-row-text-external-link"
                rel="noreferrer noopener"
              >
                {t('components.imageUploadComponent.imageField.errorMessages.helpCenterLink')}
              </a>
            )}
          </div>
        ) : null}
      </div>
      <Form
        name="pluginImagePlaceholder"
        value={withImageData(data)}
        prepare={withImageData}
        onChange={(data) => {
          if (hasImageEditorFeature) {
            onSave(withMergedImageData(data).remove('imageData'))
            onEdit('editImage')
          } else {
            onSave(withoutImageEditorData(withMergedImageData(data).remove('imageData')))
          }
        }}
        className="dali-form"
      >
        <ImageField
          withImageInfo
          name="imageData"
          onError={(error: Error) => setError(error)}
          onChange={() => setError(null)}
          storeFile={config?.storeFile}
        />
      </Form>
    </div>
  )

  /**
   * Renders the image in the storefront or in the editor. Renders in image
   * editor mode when the edit action is "editImage".
   *
   * The image editor uses the original image as the source for editing:
   *
   * - Initially, the "src" property is always set to the original image.
   * - After the first image editor "init" event, the "refSrc" property is set
   *   to the original image and the "src" property is set to the edited image.
   */
  function renderImage() {
    // We can only set the aspect ratio if we know the width and height of the
    // image. Older user-uploaded images don’t have this information.
    const aspectRatio = data.get('width') && data.get('height') ? data.get('width') / data.get('height') : undefined

    const aspectRatioMap = isMultiColumn ? multiColumnAspectRatioMap : singleColumnAspectRatioMap

    const image = (
      <>
        <div className="dali-plugin-image-content">
          {editAction === 'editImage' ? (
            <ImageEditor
              src={
                config?.imageUrl
                  ? config.imageUrl(data.get('refSrc') || data.get('src'))
                  : data.get('refSrc') || data.get('src')
              }
              aspectRatioMap={aspectRatioMap}
              edit={
                data.get('edit')?.toJS() ||
                // This fallback is needed for images that were created before
                // the image editor feature was introduced. To convert to the
                // image editor, we initialize with the aspect ratio of the
                // image so there’s no visual change.
                defaultData.get('edit').set('aspectRatio', { label: 'original', value: aspectRatio }).toJS()
              }
              onInit={async (imageFile) => {
                // Absence of "refSrc" indicates that the image is initially
                // auto-edited with the image editor. To save the auto-edited
                // image whilst keeping the original image, we save the edited
                // image and set the current value of "src" to "refSrc".
                if (!data.get('refSrc')) {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  const imageUploadResponse = await config!.storeFile!(imageFile)
                  onSave(
                    data.merge(
                      Immutable.fromJS({
                        src: imageUploadResponse.absoluteDownloadUrl,
                        width: imageUploadResponse.width,
                        height: imageUploadResponse.height,
                        refSrc: data.get('src'),
                      }),
                    ),
                    true,
                  )
                }
              }}
              onCancel={onCancel}
              onChange={async (edit, imageFile, newImageSourceFile) => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                const storeFile = config!.storeFile!
                const imageUploads = [storeFile(imageFile)]
                if (newImageSourceFile) {
                  imageUploads.push(storeFile(newImageSourceFile))
                }
                const [imageUploadResponse, newSourceImageUploadResponse] = await Promise.all(imageUploads)
                onSave(
                  data.merge(
                    Immutable.fromJS({
                      edit,
                      src: imageUploadResponse.absoluteDownloadUrl,
                      width: imageUploadResponse.width,
                      height: imageUploadResponse.height,
                      refSrc: newSourceImageUploadResponse
                        ? newSourceImageUploadResponse.absoluteDownloadUrl
                        : data.get('refSrc'),
                    }),
                  ),
                )
              }}
            />
          ) : (
            <div style={{ aspectRatio }}>
              <LazyImage
                src={config?.imageUrl ? config.imageUrl(data.get('src')) : data.get('src')}
                width={data.get('width')}
                height={data.get('height')}
                style={{
                  clipPath: data.getIn(['edit', 'aspectRatio', 'label']) === 'circle' ? 'circle(50% at center)' : '',
                }}
                alt={data.get('alt')}
                onLoad={handleImageLoaded}
              />
            </div>
          )}
        </div>
        {editAction !== 'editImage' && (
          <CtaTextOverlay
            textSettings={{
              text: data.get('text'),
              headline: data.get('headline'),
              buttontext: data.get('buttontext'),
              buttonenabled: data.get('buttonenabled'),
            }}
            image={data}
            isCircle={data.getIn(['edit', 'aspectRatio', 'label']) === 'circle'}
          />
        )}
      </>
    )

    if (!editorView && isLinked) {
      const linkTarget = data.get('opentab') ? '_blank' : undefined
      const rel = linkTarget ? 'noopener noreferrer' : undefined

      return (
        <a href={data.get('link')} target={linkTarget} rel={rel} className="dali-image-wrapper">
          {image}
        </a>
      )
    }

    return <div className="dali-image-wrapper">{image}</div>
  }

  const renderSettingsLayer = () => {
    return (
      <SettingsLayer
        referenceElement={ref.current}
        placement="right"
        onActiveStateChange={handlePluginActiveStateChange}
        onEscapeKeyDown={onCancel}
        className={editAction === 'crop' ? 'dali-settingslayer-image-crop' : null}
      >
        {({ updatePosition, renderLayout }) => {
          updateSettingsLayerPosition.current = updatePosition

          return createElement(editAction === 'crop' ? CropSettings : Settings, {
            data,
            onDataChange,
            config,
            onCancel,
            renderLayout,
            updateSettingsLayerPosition: updatePosition,
            onSave: (data) => {
              setError(null)
              if (hasImageEditorFeature) {
                onSave(data)
              } else {
                onSave(withoutImageEditorData(data))
              }
            },
          })
        }}
      </SettingsLayer>
    )
  }

  if (editorView) {
    return (
      <div className={pluginActiveClasses} ref={ref}>
        {isEmpty ? emptyImage : renderImage()}
        {editorMode === 'edit' && editAction !== 'editImage' && renderSettingsLayer()}
      </div>
    )
  }

  return !isEmpty ? <div className={pluginActiveClasses}>{renderImage()}</div> : null
}

ImagePlugin.actionBarButtons = { edit: true }

export default compose(withI18n('interface'), translate())(ImagePlugin)

const singleColumnAspectRatioMap = {
  '3:1': 3 / 1,
  '3:2': 3 / 2,
  '4:3': 4 / 3,
  '5:1': 5 / 1,
  '16:9': 16 / 9,
}

const multiColumnAspectRatioMap = {
  '1:1': 1,
  '2:3': 2 / 3,
  '3:1': 3 / 1,
  '3:2': 3 / 2,
  '4:3': 4 / 3,
  '16:9': 16 / 9,
  circle: 1,
}
