import { useEffect, useRef, useState } from 'react'

import type { AspectRatioLabel } from './ImageEditor'

type ImageCanvasProps = Readonly<{
  src: string
  aspectRatio: { label: AspectRatioLabel; value?: number }
  zoomFactor: number
  offset: readonly [number, number]
  onUpdate?: (
    canvas: HTMLCanvasElement,
    sourceImage: HTMLImageElement,
    offset: readonly [number, number],
    maxOffset: readonly [number, number],
  ) => void
}>

/**
 * Draws an image on a canvas element and renders the canvas in an aspect ratio
 * box. The image will be sized to cover the entire canvas while maintaining
 * the given aspect ratio, clipping the image if necessary.
 *
 * The portion of the image that is displayed can be controlled using the
 * `zoomFactor` and `offset` props, defining the zoom level and the position of
 * the image inside the canvas, respectively.
 *
 * The `onUpdate` callback is called whenever the image is drawn on the canvas,
 * providing the canvas element, the source image element, the current offset
 * values, and the maximum offset values that can be used for the image.
 */
export function ImageCanvas({ src, aspectRatio, zoomFactor, offset, onUpdate }: ImageCanvasProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [sourceImage, setSourceImage] = useState<HTMLImageElement | null>(null)

  // Draw the image and call the onUpdate callback whenever a parameter changes.
  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')
    if (canvas && context && sourceImage) {
      const { offset: currentOffset, maxOffset } = drawImage(context, sourceImage, offset, zoomFactor)
      onUpdate?.(canvas, sourceImage, currentOffset, maxOffset)
    }
  }, [sourceImage, onUpdate, zoomFactor, offset])

  // Load the source image and set the canvas size (matching the given aspect
  // ratio) when the component is mounted or when the image source or aspect
  // ratio changes. The canvas width is set to the width of the container if
  // the image width is smaller than the container width.
  useEffect(() => {
    const canvas = canvasRef.current
    const container = containerRef.current
    if (canvas && container) {
      const image = new Image()
      image.src = src
      image.onload = () => {
        canvas.width = image.width > container.clientWidth ? image.width : container.clientWidth
        canvas.height = canvas.width / (aspectRatio.value ?? image.width / image.height)
        setSourceImage(image)
      }
    }
  }, [src, aspectRatio])

  return (
    <div ref={containerRef} style={{ aspectRatio: aspectRatio.value }}>
      <canvas
        ref={canvasRef}
        style={{
          display: 'block',
          width: '100%',
          clipPath: aspectRatio.label === 'circle' ? 'circle(50% at center)' : '',
        }}
      />
    </div>
  )
}

/**
 * Draws the given image on the canvas of the given canvas rendering context.
 *
 * The image will be sized to cover the entire canvas while maintaining its
 * aspect ratio, clipping the image if necessary. Like CSS `object-fit: cover`.
 */
function drawImage(
  context: CanvasRenderingContext2D,
  image: HTMLImageElement,
  offset: readonly [number, number],
  zoomFactor: number,
) {
  const canvas = context.canvas

  // Calculate the ratio of the canvas to the image, and use the smaller ratio
  // to calculate the proportional width and height for the image.
  const ratio = Math.min(canvas.width / image.width, canvas.height / image.height)
  let newImageWidth = image.width * ratio
  let newImageHeight = image.height * ratio

  // Calculate the aspect ratio to be used for the image source rectangle to be
  // drawn onto canvas. It is calculated based on the new proportional width
  // and height for the image, and the width and height of the canvas.
  let aspectRatio = 1
  if (newImageWidth < canvas.width) {
    aspectRatio = canvas.width / newImageWidth
  }
  // Take floating point rounding errors into account by using an epsilon error
  // margin, so that the aspect ratio is not changed if the image is already
  // correctly scaled to the canvas width. Consider the following example:
  // canvas = { width: 854, height: 854 }, image = { width: 965, height: 1600 }
  // In Chrome, this results in the following values:
  // newImageWidth = 515.0687499999999, newImageHeight = 853.9999999999999
  if (Math.abs(aspectRatio - 1) < 1e-14 && newImageHeight < canvas.height) {
    aspectRatio = canvas.height / newImageHeight
  }

  // Calculate the final new width and height for the image based on the aspect
  // ratio and the zoom factor.
  newImageWidth *= aspectRatio * zoomFactor
  newImageHeight *= aspectRatio * zoomFactor

  // Calculate the source rectangle, representing the portion of the image that
  // will be drawn onto canvas. The source rectangle is calculated based on the
  // calculated new width and height for the image, the width and height of the
  // canvas, and the offset values.
  let sourceWidth = image.width / (newImageWidth / canvas.width)
  let sourceHeight = image.height / (newImageHeight / canvas.height)

  // Calculate the offset values for the image. The offset input is clamped to
  // the maximum offset values based on the source rectangle size and the image
  // size. This is necessary to prevent the image from being drawn outside of
  // the canvas boundaries.
  //
  // Floating point source width and height rounding errors are taken into
  // account in the max offset calculations by rounding the values to a certain
  // precision. `sourceWidth` and `sourceHeight` do not directly need to be
  // rounded since the image is still drawn correctly. It’s only relevant for
  // the max offsets, so that image move controls are correctly disabled when
  // the maximum offset is reached. Consider the following example:
  // image.width = 873, sourceWidth = 872.9999999999998, rounded = 873.000000000
  // 873 - 873.000000000 = 0
  const maxOffsetX = image.width - (sourceWidth > image.width ? image.width : Number(sourceWidth.toPrecision(12)))
  const maxOffsetY = image.height - (sourceHeight > image.height ? image.height : Number(sourceHeight.toPrecision(12)))
  const offsetX = Math.min(Math.max(offset[0], -maxOffsetX), maxOffsetX)
  const offsetY = Math.min(Math.max(offset[1], -maxOffsetY), maxOffsetY)

  // Calculate the source rectangle position taking into account the offset
  // values. Center the source rectangle.
  let sx = (image.width - sourceWidth - offsetX) * 0.5
  let sy = (image.height - sourceHeight - offsetY) * 0.5

  // Clamp the source rectangle values to the image boundaries.
  // This is necessary because the source rectangle values can be negative or
  // larger than the image width or height, which would cause the image to be
  // drawn incorrectly.
  if (sourceWidth > image.width) sourceWidth = image.width
  if (sourceHeight > image.height) sourceHeight = image.height
  if (sx < 0) sx = 0
  if (sy < 0) sy = 0

  // Clear the canvas and draw the image onto it using the calculated values.
  context.clearRect(0, 0, canvas.width, canvas.height)
  context.drawImage(image, sx, sy, sourceWidth, sourceHeight, 0, 0, canvas.width, canvas.height)

  // Return the possibly corrected offset values that were used to draw the image,
  // and the maximum offset values that can be used for the image.
  return {
    offset: [offsetX, offsetY] as const,
    maxOffset: [maxOffsetX, maxOffsetY] as const,
  }
}
