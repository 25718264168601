import PropTypes from 'prop-types'

import Link from '../../templateComponents/Link'

function LegalFooterNavigation({ legalPages = [], footerPages = [] }) {
  return (
    <ul className="pages">
      {legalPages.concat(footerPages.filter((p) => p.isVisible)).map((page) => (
        <li className="pages-item" key={page.id}>
          <Link to={page.href} className="pages-item-link">
            {page.title}
          </Link>
        </li>
      ))}
    </ul>
  )
}

LegalFooterNavigation.propTypes = {
  legalPages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  footerPages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
}

export default LegalFooterNavigation
