import type { ReactElement } from 'react'
import { useRef, useState } from 'react'
import cc from 'classcat'
import loadable from '@loadable/component'

import type { IconPluginData } from './IconSettings'
import { emptyIconPluginData } from '../../../../editorSidebarBlockTemplates'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './IconSettings'))

export default compose(withI18n('interface'), translate())(IconPlugin)

function IconPlugin({
  data: immutableData,
  editorMode,
  editorView,
  onEdit,
  onCancel,
  onDataChange,
  onSave,
  t,
}: WorkspacePluginProps & TranslateProps): ReactElement | null {
  const data: IconPluginData = immutableData.toJS()

  const [isSettingActive, setIsSettingActive] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const pluginActiveClasses = cc([
    {
      'dali-grid-element-highlighted': isSettingActive,
    },
  ])

  const renderSettingsLayer = () => {
    return (
      <SettingsLayer
        referenceElement={ref.current}
        placement="right"
        onActiveStateChange={(isSettingActive: boolean) => setIsSettingActive(isSettingActive)}
        onEscapeKeyDown={onCancel}
      >
        {({ renderLayout }) => (
          <SettingsForm
            data={data}
            onDataChange={onDataChange}
            onSave={onSave}
            onCancel={onCancel}
            renderLayout={renderLayout}
          />
        )}
      </SettingsLayer>
    )
  }

  if (editorView) {
    return (
      <div className={pluginActiveClasses} ref={ref}>
        {data.type ? (
          renderIcon()
        ) : (
          <div className="dali-grid-element-placeholder">
            <span className="dali-plugin-icon-placeholder" />
            <button className="dali-plugin-icon-placeholder-button-add" onClick={() => onEdit()}>
              {t('components.iconElementComponent.addIconButton.label')}
            </button>
          </div>
        )}
        {editorMode === 'edit' ? renderSettingsLayer() : null}
      </div>
    )
  }

  if (!data.type) return null

  return renderIcon()

  function renderIcon() {
    return (
      <div
        className={cc([
          'dali-plugin-icon',
          {
            'no-bg-or-border-styles':
              data.backgroundColor === emptyIconPluginData.backgroundColor &&
              data.backgroundSize === emptyIconPluginData.backgroundSize &&
              data.borderSize === emptyIconPluginData.borderSize,
          },
        ])}
        style={{
          '--alignment': data.alignment,
          '--spacing': data.spacing + 'px',
          '--sizing': data.sizing,
          '--color': data.color,
          '--backgroundSize': data.backgroundSize,
          '--backgroundRadius': data.backgroundRadius + '%',
          '--backgroundColor': data.backgroundColor,
          '--borderSize': data.borderSize + 'px',
          '--borderColor': data.borderColor,
        }}
      >
        {data.link ? (
          <a
            href={data.link}
            target={data.opentab ? '_blank' : undefined}
            rel={data.opentab ? 'noreferrer noopener' : undefined}
          >
            <span className={`ep-${data.type}-icon`} />
          </a>
        ) : (
          <span className={`ep-${data.type}-icon`} />
        )}
      </div>
    )
  }
}

IconPlugin.actionBarButtons = { edit: true }
