import type { ComponentProps } from 'react'
import { useRef, useState } from 'react'

import { EditorSpotlight } from '../EditorSpotlight'
import { ImageCanvas } from './ImageCanvas'
import { ImageEditorMenu } from './ImageEditorMenu'
import { ImageMoveControls } from './ImageMoveControls'
import { getFileFromCanvas, getFileFromImage, getFilename } from './ImageEditor.helpers'
import { useImageOffset } from './useImageOffset'
import compose from '../../utils/compose'
import translate from '../../utils/translate'
import withI18n from '../withI18n'

type ImageEditorProps = Readonly<{
  src: string
  aspectRatioMap: AspectRatioMap
  edit: EditState
  onInit: (imageFile: File) => Promise<void>
  onChange: (edit: EditState, imageFile: File, newImageSourceFile?: File) => Promise<void>
  onCancel: () => void
  t: TranslateProps['t']
}>

export type AspectRatioLabel = '1:1' | '2:3' | '3:1' | '3:2' | '4:3' | '5:1' | '16:9' | 'circle' | 'original'
type AspectRatioMap = Partial<Record<'1:1' | '2:3' | '3:1' | '3:2' | '4:3' | '5:1' | '16:9' | 'circle', number>>

type EditState = Readonly<{
  aspectRatio: { label: AspectRatioLabel; value?: number }
  offset: readonly [number, number]
  zoom: number
}>

/**
 * Image editor component that is used in image content elements, allowing
 * users to control the portion of the image that should be displayed, as well
 * as the aspect ratio and zoom level.
 *
 * @param src The source image to be edited
 * @param aspectRatioMap The aspect ratio options to be displayed in the editor
 * @param edit The edit state (aspect ratio, offset, zoom factor)
 * @param onInit Callback to handle the initial render of the image, e.g. initial auto-edit
 * @param onChange Callback to handle changes to the image, i.e. save
 * @param onCancel Callback to handle canceling the editing
 */
export const ImageEditor = compose(
  withI18n('interface'),
  translate('components.imageEditorComponent'),
)(ImageEditorRaw) as (props: Omit<ComponentProps<typeof ImageEditorRaw>, 't'>) => JSX.Element

export function ImageEditorRaw({ src, aspectRatioMap, edit, onInit, onChange, onCancel, t }: ImageEditorProps) {
  const [spotlightReferenceElement, setSpotlightReferenceElement] = useState<HTMLDivElement | null>(null)
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  const sourceImageRef = useRef<HTMLImageElement | null>(null)

  const [imageSrc, setImageSrc] = useState(src)
  const [filename, setFilename] = useState(getFilename(src))

  const [isReady, setIsReady] = useState(false)
  const [zoomFactor, setZoomFactor] = useState(edit.zoom)
  const [aspectRatio, setAspectRatio] = useState(edit.aspectRatio)

  const [maxImageOffset, setMaxImageOffset] = useState<readonly [number, number]>([0, 0])
  const [imageOffset, setImageOffset, controls, canDrag, isDragging] = useImageOffset(
    canvas,
    edit.offset,
    maxImageOffset,
  )

  // This can be replaced with `useTransition` in React 19
  const [isPending, setIsPending] = useState(false)
  async function pending(callbackFn: () => Promise<void>) {
    setIsPending(true)
    try {
      await callbackFn()
    } finally {
      setIsPending(false)
    }
  }

  function handleFileChange(file: File) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      setImageSrc(reader.result as string)
      setFilename(file.name)
      setZoomFactor(1)
      setImageOffset([0, 0])

      // If the current aspect ratio option is set to "original"
      // and a new image is uploaded, update the aspect ratio value
      // to the new image's original aspect ratio.
      if (aspectRatio.label === 'original') {
        const image = new Image()
        image.src = reader.result as string
        image.onload = () => {
          setAspectRatio({
            label: 'original',
            value: image.width / image.height,
          })
        }
      }
    }
  }

  async function handleChange() {
    await pending(async () => {
      if (canvas && sourceImageRef.current) {
        const filePromises = [getFileFromCanvas(canvas, filename)]

        // A data URL indicates that a new image was chosen from the device via
        // the file input. Pass the new image file to the onChange callback.
        if (imageSrc.startsWith('data:')) {
          filePromises.push(getFileFromImage(sourceImageRef.current, filename))
        }

        const [imageFile, newImageSourceFile] = await Promise.all(filePromises)
        const currentEdit: EditState = { aspectRatio, offset: imageOffset, zoom: zoomFactor }

        await onChange(currentEdit, imageFile, newImageSourceFile)
      }
    })
  }

  return (
    <>
      <div style={{ aspectRatio: aspectRatio.value }} ref={setSpotlightReferenceElement} />
      <EditorSpotlight referenceElement={spotlightReferenceElement} onCancel={onCancel}>
        <div className="ep-image-editor" data-can-drag={canDrag || null} data-is-dragging={isDragging || null}>
          {isReady && (
            <>
              <ImageEditorMenu
                t={t}
                referenceElement={spotlightReferenceElement}
                disabled={isPending}
                aspectRatio={aspectRatio}
                aspectRatioOptions={{
                  ...aspectRatioMap,
                  original: sourceImageRef.current!.width / sourceImageRef.current!.height,
                }}
                zoomFactor={zoomFactor}
                onZoomFactorChange={setZoomFactor}
                onAspectRatioChange={setAspectRatio}
                onFileChange={handleFileChange}
                onCancel={onCancel}
                onSave={handleChange}
              />
              <ImageMoveControls controls={controls} t={t} />
            </>
          )}
          <ImageCanvas
            src={imageSrc}
            aspectRatio={aspectRatio}
            zoomFactor={zoomFactor}
            offset={imageOffset}
            onUpdate={(canvas, sourceImage, offset, maxOffset) => {
              sourceImageRef.current = sourceImage

              // ImageCanvas returns the offset that was used to draw the image. It
              // is based on the offset that was passed in but might differ to keep
              // the image within the bounds of the canvas. Update the offset state
              // with the used offset if it differs.
              if (imageOffset[0] !== offset[0] || imageOffset[1] !== offset[1]) {
                setImageOffset(offset)
              }

              // For button state management
              if (maxImageOffset[0] !== maxOffset[0] || maxImageOffset[1] !== maxOffset[1]) {
                setMaxImageOffset(maxOffset)
              }

              // Default to the aspect ratio of the source image
              if (!aspectRatio.value) {
                setAspectRatio({ label: 'original', value: sourceImage.width / sourceImage.height })
              }

              if (!isReady) {
                setIsReady(true)
                setCanvas(canvas)
                pending(async () => onInit(await getFileFromCanvas(canvas, filename)))
              }
            }}
          />
        </div>
      </EditorSpotlight>
    </>
  )
}
